
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class BaseSlideUseCase extends Vue {
    @Prop() content!: {
        video: string;
        title: string;
        lead: string;
        logo: string;
        to: string;
        features: {
            tag: string;
        }[];
    };
}
