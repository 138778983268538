
import { BButton } from 'bootstrap-vue';
import { Component, Vue } from 'vue-property-decorator';
import { Hooper, Slide, Pagination as HooperPagination } from 'hooper';
import 'hooper/dist/hooper.css';
import { ALT_TEXT, TITLES } from '@thxnetwork/public/utils/constants';
import BaseSlideUseCase from '@thxnetwork/public/components/BaseSlideUseCase.vue';
import { content } from '../views/UseCaseDetail.vue';

@Component({
    components: {
        'b-button': BButton,
        'hooper': Hooper,
        'slide': Slide,
        'hooper-pagination': HooperPagination,
        BaseSlideUseCase,
    },
})
export default class BaseUseCases extends Vue {
    content = content;
    ALT_TEXT = ALT_TEXT;
    TITLES = TITLES;
    hooperSettings = {
        infiniteScroll: true,
        itemsToShow: 1,
        centerMode: false,
        breakpoints: {
            960: {
                itemsToShow: 3.5,
            },
        },
    };
}
